@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans text-gray-800;
  overflow-y: scroll;

}

body > div:first-child {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto auto 1fr;
}

nav {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

nav > div {
  max-width: 110ch;
  margin-left: auto;
  margin-right: auto;
}

main {
  max-width: 70ch;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;

  display: grid;
  place-items: center;
}

main:has(.spelling2) {
  max-width: none;
}

main > div {
  min-height: 20rem;
}
h1 {
  @apply font-bold text-3xl text-gray-800;
  @apply mb-4;

  line-height: 1.3;
  margin-bottom: 2rem;
}

h2 {
  @apply font-semibold text-3xl text-gray-900;

  line-height: 1.3;
  margin-bottom: 2rem;
}

* + h2 {
  margin-top: 3rem;
}

h1 + h2 {
  @apply mt-3;
}

h3 {
  @apply mt-6;
  @apply font-bold text-xl text-gray-800;
}

h2 + h3 {
  @apply mt-2;
}

h4 {
  @apply mt-2;
  @apply font-semibold text-lg text-gray-800;
}

a.button,
button {
  display: inline-block;
  @apply bg-indigo-600 rounded shadow;
  @apply border-4 border-transparent;
  @apply px-4 py-2;
  @apply font-semibold text-white;

  &:hover:not(:disabled) {
    @apply bg-indigo-700;
    @apply text-white;
  }

  &:disabled {
    @apply bg-indigo-400;
  }


  &:focus {
    @apply outline-none;
  }
  &:focus-visible {
    @apply outline-none border-2 border-blue-500;
  }
}

a {
  @apply text-blue-600 font-semibold;

  &:hover {
    @apply text-blue-800;
  }
}

video {
  width: 100%;
}

.bg-transition {
  transition: background-color ease-out 200ms;
}

.btn {
  @apply bg-blue-100 rounded px-4 py-2;
}

pre {
  @apply bg-gray-100 mb-4 p-3 rounded;
}

code {
  @apply bg-gray-100 text-gray-700 text-sm rounded;

  padding: 0.2rem 0.4rem;
}

kbd {
  font-size: 0.75em;
  padding: 0.0625rem 0.5rem;
  border-radius: 0.125rem;
  border: 2px solid;
  box-shadow: 1px 1px 0 currentColor, 2px 2px 0 currentColor;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  background: white;
  color: black;
}

ol {
  list-style: decimal;
  padding: revert;
}

.cloudwords ul {
  list-style: revert;
  padding: revert;
}

li + li {
  margin-top: 0.75rem;
}

nav li + li {
  margin-top: 0;
}

.flow > * + *,
p + p {
  margin-top: 1rem;
}

.dates-to-book > * + *,
.possible-dates > * + * {
  margin-top: 0.25rem;
}

.dates-to-book {
  list-style: unset;
  list-style-position: inside;
}

label,
input :not([type="radio"]) {
  display: block;
}

label + input {
  margin-top: 0.5rem;
}

input {
  @apply border-2;

  padding: 0.3rem;
}

/* https://archive.hankchizljaw.com/wrote/creating-a-full-bleed-css-utility/ */
.full-bleed {
  width: calc(100vw - 2rem);
  margin-left: 50%;
  transform: translateX(-50%);
}

.content {
  @apply text-gray-700;
  @apply leading-relaxed;

  ol,
  ul {
    @apply pl-4;

    list-style: disc inside;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
  }
}

.instructions {
  min-width: 40ch;
}

.question {
  display: grid;
  grid-template-areas: "heading"
                       "question"
                       "feedback";

  grid-template-rows: auto auto 10rem;
  justify-items: center;
}

.question > :first-child{
  align-self: start;
  margin-bottom: 2rem;
}

@media (min-width: 70ch) {
  .shuffled-options {
    /* prevent layout shifts going from one question to the next */
    min-width: 50ch;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .shuffled-options > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .shuffled-options > *:last-child {
    grid-column: 1 / span 2;
    text-align: center;
  }

  .shuffled-options input {
    display: none;
  }

  .shuffled-options label span {
    padding-left: 0;
  }

  .shuffled-options > label > div {
    margin: 0;
  }
}

.fill-in-the-blanks {
  display: flex;
  white-space: pre;
}

.blanks {
  display: grid;
  grid-template-areas: "word";
  font-weight: bold;
  text-align: center;
}

.blanks.right-aligned {
  text-align: right;
}

.blanks > * {
  grid-area: word;
  visibility: hidden;
}
.blanks > .visible {
  visibility: visible;
}
.blanks > .empty {
  border-bottom: 1.5px solid;
}

.context2 {
  display: grid;
  grid-template-rows: auto 10rem;
  justify-items: center;
}

.question > :first-child{
  align-self: start;
  margin-bottom: 2rem;
}

.context2 > :first-child {
  display: grid;
  justify-content: center;
}

.context2 .audio-button {
  justify-self: center;
  margin-bottom: 2rem;
}

.context2-input {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.context2-input label {
  font-weight: bold;
}

.context2-input input {
  margin-top: 0;
  flex-grow: 1;
}

.endInfo > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 40ch) {
  .calendar button,
  .endInfo button,
  .instructions button {
    margin-right: auto;
  }
}

@media (min-width: 50rem) {
  .word-presentation {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    grid-gap: 6rem 4rem;
  }

  .word-presentation > *:nth-child(1) {
    grid-row: 2;
    grid-column: 3 / 5;
  }

  .word-presentation > *:nth-child(2) {
    grid-row: 3;
    grid-column: 3 / 5;
  }

  .word-presentation > *:nth-child(3) {
    grid-row: 2;
    grid-column: 1 / 3;
  }

  .word-presentation > *:nth-child(4) {
    grid-row: 2;
    grid-column: 5 / 7;
  }

  .word-presentation > *:nth-child(5) {
    grid-row: 1;
    grid-column: 3 / 5;
  }

  .word-presentation .summary {
    position: relative;
  }

  .word-presentation > *:not(:nth-child(2)) .details {
    position: absolute;
  }

  .word-presentation > button:nth-child(2) {
    @apply p-4;

    margin: 0;
    border: none;
    justify-content: flex-start;
  }
}

.word-presentation > *:nth-child(5) ul {
  list-style-type: disc;
  list-style-position: inside;
}

.word-presentation > *:nth-child(5) ul > li + li {
  margin-top: 0.25rem;
}

.with-thought-bubble {
  display: flex;
  align-items: end;
  justify-self: end;
}

.avatar-with-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.thought-bubble {
  width: 5rem;
  height: 4rem;
  border-radius: 3rem;
  border: 2px solid;
  position: relative;
  margin-left: 1.5rem;
  margin-bottom: 5rem;
}

.thought-bubble::before {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 3rem;
  border: 2px solid;
  position: absolute;
  bottom: -1rem;
  left: -1rem;
}

.thought-bubble::after {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 3rem;
  border: 2px solid;
  position: absolute;
  bottom: -1.5rem;
  left: -1.5rem;
}

.speech-bubble {
  padding: 1rem;
  min-width: 5rem;
  max-width: 30ch;
  min-height: 4rem;
  border-radius: 0.25rem;
  border: 2px solid;
  position: relative;
  margin-left: -1rem;
  margin-bottom: 5rem;
}

.speech-bubble::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 16px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid;
}

.speech-bubble::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 18px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid white;
}

@media (min-width: 40ch) {
  .context-understanding-3 .first-row {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .context-understanding-3 .first-row button {
    margin: 0;
  }

  .context-understanding-3--grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 1rem;
  }
}

.context-understanding-3 .with-thought-bubble:not(.bubble-left) {
  justify-self: start;
}

.context-understanding-3--grid > * {
  opacity: 0.25;
}

.context-understanding-3--grid > .speaking {
  opacity: 1;
}

.context-understanding-3 .bubble-left {
  flex-direction: row-reverse;
  margin-left: 3rem;
}

.context-understanding-3 label {
  align-self: start;
  font-weight: bold;
}

.context-understanding-3 textarea {
  margin-top: 0.5rem;
}

.bubble-left .speech-bubble {
  margin-right: -1rem;
  margin-left: 0;
  margin-bottom: 5rem;
}

.bubble-left .speech-bubble::before {
  right: 16px;
  left: unset;
}

.bubble-left .speech-bubble::after {
  right: 18px;
  left: unset;
}

@media (min-width: 40ch) {
  .yes-no-buttons {
    display: grid;
    grid-template-columns: 5rem 5rem;
    justify-content: center;
    gap: 4rem;
  }
  .yes-no-buttons button:first-child {
    @apply bg-red-700;
  }
  .yes-no-buttons button:first-child:hover {
    @apply bg-red-500;
  }
  .yes-no-buttons button:nth-child(2) {
    @apply bg-green-700;
  }
  .yes-no-buttons button:nth-child(2):hover {
    @apply bg-green-500;
  }
}

@media (min-width: 40ch) {
  .spr-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .spr-buttons > :nth-child(3) {
    grid-column: 1 / 3;
  }
}

.sentence-in-synonym {
  @apply border-2 p-12;
}

.sentence-in-synonym > div,
.sentence-in-synonym > div > input {
  display: inline-block;
  width: 15ch;
}

.sentence-in-synonym > span:first-child {
  margin-right: 0.5rem;
}

.sentence-in-synonym > span:last-child {
  margin-left: 0.5rem;
}

.sentence-in-synonym > div {
  margin-top: 0.5rem;
}

.vks {
  min-height: 40rem;
}

.progress-bar {
  display: grid;
  grid-template-columns: repeat(var(--count, 5), 1fr);
}

.progress-bar > * {
  padding: calc(0.5rem - 4px);
  border-bottom: none;
  text-align: center;
  opacity: 0.5;
  position: relative;
}

.progress-bar > *:last-child {
  border-right: none;
}

.progress-bar .active {
  opacity: 1;
}

.progress-bar > *:first-child {
  @apply text-green-700 border-green-400 bg-green-200;
}

.progress-bar > *:first-child .activity-progress--inner {
  @apply bg-green-400;
}

.progress-bar > *:nth-child(2) {
  @apply text-blue-700 border-blue-400 bg-blue-200;
}

.progress-bar > *:nth-child(2) .activity-progress--inner {
  @apply bg-blue-400;
}

.progress-bar > *:nth-child(3) {
  @apply text-pink-700 border-pink-400 bg-pink-200;
}

.progress-bar > *:nth-child(3) .activity-progress--inner {
  @apply bg-pink-400;
}

.progress-bar > *:nth-child(4) {
  @apply text-purple-700 border-purple-400 bg-purple-200;
}

.progress-bar > *:nth-child(4) .activity-progress--inner {
  @apply bg-purple-400;
}

.progress-bar > *:nth-child(5) {
  @apply text-yellow-700 border-yellow-400 bg-yellow-200;
}

.progress-bar > *:nth-child(5) .activity-progress--inner {
  @apply bg-yellow-400;
}

.progress-bar > *:nth-child(6) {
  @apply text-indigo-700 border-indigo-400 bg-indigo-200;
}

.progress-bar > *:nth-child(6) .activity-progress--inner {
  @apply bg-indigo-400;
}

.progress-bar > *:nth-child(7) {
  @apply text-gray-700 border-gray-400 bg-gray-200;
}

.progress-bar > *:nth-child(7) .activity-progress--inner {
  @apply bg-gray-400;
}

.progress-bar .activity-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
}

.progress-bar .activity-progress--inner {
  height: 100%;
}
