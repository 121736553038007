html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

b {
  font-weight: bolder;
}

code, kbd {
  font-family: monospace;
  font-size: 1em;
}

img {
  border-style: none;
}

button, input, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button {
  text-transform: none;
}

button, [type="button"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

details {
  display: block;
}

summary {
  display: list-item;
}

dl, h1, h2, h4, p, pre {
  margin: 0;
}

button {
  background-color: #0000;
  background-image: none;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e2e8f0;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #a0aec0;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #a0aec0;
}

input::placeholder, textarea::placeholder {
  color: #a0aec0;
}

button, [role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1, h2, h4 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

button, input, textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

pre, code, kbd {
  font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

img, svg, video, audio, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

.form-radio:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media not print {
  .form-radio::-ms-check {
    color: #0000;
    background: inherit;
    border-width: 1px;
    border-color: inherit;
    border-radius: inherit;
  }
}

.form-radio {
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1em;
  width: 1em;
  color: #4299e1;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #e2e8f0;
  border-radius: 100%;
  flex-shrink: 0;
  display: inline-block;
}

.form-radio:focus {
  border-color: #63b3ed;
  outline: none;
  box-shadow: 0 0 0 3px #4299e180;
}

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.bg-gray-200 {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.bg-gray-400 {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity));
}

.bg-gray-500 {
  --bg-opacity: 1;
  background-color: #a0aec0;
  background-color: rgba(160, 174, 192, var(--bg-opacity));
}

.bg-red-200 {
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity));
}

.bg-red-500 {
  --bg-opacity: 1;
  background-color: #f56565;
  background-color: rgba(245, 101, 101, var(--bg-opacity));
}

.bg-green-200 {
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity));
}

.bg-green-500 {
  --bg-opacity: 1;
  background-color: #48bb78;
  background-color: rgba(72, 187, 120, var(--bg-opacity));
}

.bg-indigo-200 {
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity));
}

.border-gray-300 {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity));
}

.border-gray-600 {
  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity));
}

.border-red-500 {
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity));
}

.border-green-500 {
  --border-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--border-opacity));
}

.border-indigo-600 {
  --border-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--border-opacity));
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.content-center {
  align-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.flex-grow {
  flex-grow: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-last {
  order: 9999;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.h-6 {
  height: 1.5rem;
}

.h-12 {
  height: 3rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.text-sm {
  font-size: .875rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.opacity-75 {
  opacity: .75;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-16 {
  padding: 4rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-16 {
  padding-top: 4rem;
}

.placeholder-gray-600::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity));
}

.placeholder-gray-600:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity));
}

.placeholder-gray-600::placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity));
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.text-gray-500 {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}

.text-gray-800 {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
}

.text-red-600 {
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity));
}

.text-green-500 {
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity));
}

.text-indigo-500 {
  --text-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--text-opacity));
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.align-baseline {
  vertical-align: baseline;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.w-6 {
  width: 1.5rem;
}

.w-12 {
  width: 3rem;
}

.w-full {
  width: 100%;
}

.gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

body {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  overflow-y: scroll;
}

body > div:first-child {
  min-height: 100vh;
  grid-template-rows: auto auto 1fr;
  display: grid;
}

nav {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

nav > div {
  max-width: 110ch;
  margin-left: auto;
  margin-right: auto;
}

main {
  max-width: 70ch;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 1rem;
  display: grid;
}

main:has(.spelling2) {
  max-width: none;
}

main > div {
  min-height: 20rem;
}

h1 {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
  margin-bottom: 2rem;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.3;
}

h2 {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity));
  margin-bottom: 2rem;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.3;
}

* + h2 {
  margin-top: 3rem;
}

h1 + h2 {
  margin-top: .75rem;
}

h4 {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
  margin-top: .5rem;
  font-size: 1.125rem;
  font-weight: 600;
}

a.button, button {
  --bg-opacity: 1;
  background-color: #5a67d8;
  background-color: rgba(90, 103, 216, var(--bg-opacity));
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  border-width: 4px;
  border-color: #0000;
  border-radius: .25rem;
  padding: .5rem 1rem;
  font-weight: 600;
  display: inline-block;
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

a.button:hover:not(:disabled), button:hover:not(:disabled) {
  --bg-opacity: 1;
  background-color: #4c51bf;
  background-color: rgba(76, 81, 191, var(--bg-opacity));
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

a.button:disabled, button:disabled {
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity));
}

a.button:focus, button:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

a.button:focus-visible, button:focus-visible {
  outline-offset: 2px;
  --border-opacity: 1;
  border-width: 2px;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity));
  outline: 2px solid #0000;
}

a {
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity));
  font-weight: 600;
}

a:hover {
  --text-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--text-opacity));
}

video {
  width: 100%;
}

.btn {
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity));
  border-radius: .25rem;
  padding: .5rem 1rem;
}

pre {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem;
}

code {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
  border-radius: .25rem;
  padding: .2rem .4rem;
  font-size: .875rem;
}

kbd {
  color: #000;
  background: #fff;
  border: 2px solid;
  border-radius: .125rem;
  margin-left: .25rem;
  margin-right: .25rem;
  padding: .0625rem .5rem;
  font-size: .75em;
  box-shadow: 1px 1px, 2px 2px;
}

.cloudwords ul {
  list-style: revert;
  padding: revert;
}

li + li {
  margin-top: .75rem;
}

nav li + li {
  margin-top: 0;
}

.flow > * + *, p + p {
  margin-top: 1rem;
}

.dates-to-book > * + *, .possible-dates > * + * {
  margin-top: .25rem;
}

.dates-to-book {
  list-style: unset;
  list-style-position: inside;
}

label, input :not([type="radio"]) {
  display: block;
}

label + input {
  margin-top: .5rem;
}

input {
  border-width: 2px;
  padding: .3rem;
}

.full-bleed {
  width: calc(100vw - 2rem);
  margin-left: 50%;
  transform: translateX(-50%);
}

.content {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
  line-height: 1.625;
}

.content ul {
  padding-left: 1rem;
  list-style: inside;
}

.content ul ul {
  list-style-type: circle;
}

.instructions {
  min-width: 40ch;
}

.question {
  grid-template-rows: auto auto 10rem;
  grid-template-areas: "heading"
                       "question"
                       "feedback";
  justify-items: center;
  display: grid;
}

.question > :first-child {
  align-self: start;
  margin-bottom: 2rem;
}

@media (min-width: 70ch) {
  .shuffled-options {
    min-width: 50ch;
    grid-gap: .75rem;
    grid-template-columns: 1fr 1fr;
    margin-bottom: .75rem;
    display: grid;
  }

  .shuffled-options > * {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .shuffled-options > :last-child {
    text-align: center;
    grid-column: 1 / span 2;
  }

  .shuffled-options input {
    display: none;
  }

  .shuffled-options label span {
    padding-left: 0;
  }

  .shuffled-options > label > div {
    margin: 0;
  }
}

.fill-in-the-blanks {
  white-space: pre;
  display: flex;
}

.blanks {
  text-align: center;
  grid-template-areas: "word";
  font-weight: bold;
  display: grid;
}

.blanks.right-aligned {
  text-align: right;
}

.blanks > * {
  visibility: hidden;
  grid-area: word;
}

.blanks > .visible {
  visibility: visible;
}

.blanks > .empty {
  border-bottom: 1.5px solid;
}

.context2 {
  grid-template-rows: auto 10rem;
  justify-items: center;
  display: grid;
}

.question > :first-child {
  align-self: start;
  margin-bottom: 2rem;
}

.context2 > :first-child {
  justify-content: center;
  display: grid;
}

.context2 .audio-button {
  justify-self: center;
  margin-bottom: 2rem;
}

.context2-input {
  width: 100%;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.context2-input label {
  font-weight: bold;
}

.context2-input input {
  flex-grow: 1;
  margin-top: 0;
}

.endInfo > div {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 40ch) {
  .calendar button, .endInfo button, .instructions button {
    margin-right: auto;
  }
}

@media (min-width: 50rem) {
  .word-presentation {
    max-width: 60rem;
    grid-gap: 6rem 4rem;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: grid;
  }

  .word-presentation > :nth-child(1) {
    grid-area: 2 / 3 / auto / 5;
  }

  .word-presentation > :nth-child(2) {
    grid-area: 3 / 3 / auto / 5;
  }

  .word-presentation > :nth-child(3) {
    grid-area: 2 / 1 / auto / 3;
  }

  .word-presentation > :nth-child(4) {
    grid-area: 2 / 5 / auto / 7;
  }

  .word-presentation > :nth-child(5) {
    grid-area: 1 / 3 / auto / 5;
  }

  .word-presentation .summary {
    position: relative;
  }

  .word-presentation > :not(:nth-child(2)) .details {
    position: absolute;
  }

  .word-presentation > button:nth-child(2) {
    border: none;
    justify-content: flex-start;
    margin: 0;
    padding: 1rem;
  }
}

.word-presentation > :nth-child(5) ul {
  list-style-type: disc;
  list-style-position: inside;
}

.word-presentation > :nth-child(5) ul > li + li {
  margin-top: .25rem;
}

.with-thought-bubble {
  justify-self: end;
  align-items: end;
  display: flex;
}

.avatar-with-name {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.speech-bubble {
  min-width: 5rem;
  max-width: 30ch;
  min-height: 4rem;
  border: 2px solid;
  border-radius: .25rem;
  margin-bottom: 5rem;
  margin-left: -1rem;
  padding: 1rem;
  position: relative;
}

.speech-bubble:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  position: absolute;
  bottom: -10px;
  left: 16px;
}

.speech-bubble:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #fff;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  position: absolute;
  bottom: -8px;
  left: 18px;
}

@media (min-width: 40ch) {
  .context-understanding-3 .first-row {
    align-items: center;
    gap: 1rem;
    display: flex;
  }

  .context-understanding-3 .first-row button {
    margin: 0;
  }

  .context-understanding-3--grid {
    grid-gap: 1rem;
    grid-template-columns: 1fr max-content;
    margin-top: 2rem;
    display: grid;
  }
}

.context-understanding-3 .with-thought-bubble:not(.bubble-left) {
  justify-self: start;
}

.context-understanding-3--grid > * {
  opacity: .25;
}

.context-understanding-3--grid > .speaking {
  opacity: 1;
}

.context-understanding-3 .bubble-left {
  flex-direction: row-reverse;
  margin-left: 3rem;
}

.context-understanding-3 label {
  align-self: start;
  font-weight: bold;
}

.context-understanding-3 textarea {
  margin-top: .5rem;
}

.bubble-left .speech-bubble {
  margin-bottom: 5rem;
  margin-left: 0;
  margin-right: -1rem;
}

.bubble-left .speech-bubble:before {
  right: 16px;
  left: unset;
}

.bubble-left .speech-bubble:after {
  right: 18px;
  left: unset;
}

@media (min-width: 40ch) {
  .yes-no-buttons {
    grid-template-columns: 5rem 5rem;
    justify-content: center;
    gap: 4rem;
    display: grid;
  }

  .yes-no-buttons button:first-child {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .yes-no-buttons button:first-child:hover {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .yes-no-buttons button:nth-child(2) {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .yes-no-buttons button:nth-child(2):hover {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .spr-buttons {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }

  .spr-buttons > :nth-child(3) {
    grid-column: 1 / 3;
  }
}

.sentence-in-synonym {
  border-width: 2px;
  padding: 3rem;
}

.sentence-in-synonym > div, .sentence-in-synonym > div > input {
  width: 15ch;
  display: inline-block;
}

.sentence-in-synonym > span:first-child {
  margin-right: .5rem;
}

.sentence-in-synonym > span:last-child {
  margin-left: .5rem;
}

.sentence-in-synonym > div {
  margin-top: .5rem;
}

.vks {
  min-height: 40rem;
}

.progress-bar {
  grid-template-columns: repeat(var(--count, 5), 1fr);
  display: grid;
}

.progress-bar > * {
  text-align: center;
  opacity: .5;
  border-bottom: none;
  padding: calc(.5rem - 4px);
  position: relative;
}

.progress-bar > :last-child {
  border-right: none;
}

.progress-bar .active {
  opacity: 1;
}

.progress-bar > :first-child {
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity));
  --border-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity));
}

.progress-bar > :first-child .activity-progress--inner {
  --bg-opacity: 1;
  background-color: #68d391;
  background-color: rgba(104, 211, 145, var(--bg-opacity));
}

.progress-bar > :nth-child(2) {
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity));
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity));
}

.progress-bar > :nth-child(2) .activity-progress--inner {
  --bg-opacity: 1;
  background-color: #63b3ed;
  background-color: rgba(99, 179, 237, var(--bg-opacity));
}

.progress-bar > :nth-child(3) {
  --text-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--text-opacity));
  --border-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #fed7e2;
  background-color: rgba(254, 215, 226, var(--bg-opacity));
}

.progress-bar > :nth-child(3) .activity-progress--inner {
  --bg-opacity: 1;
  background-color: #f687b3;
  background-color: rgba(246, 135, 179, var(--bg-opacity));
}

.progress-bar > :nth-child(4) {
  --text-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--text-opacity));
  --border-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #e9d8fd;
  background-color: rgba(233, 216, 253, var(--bg-opacity));
}

.progress-bar > :nth-child(4) .activity-progress--inner {
  --bg-opacity: 1;
  background-color: #b794f4;
  background-color: rgba(183, 148, 244, var(--bg-opacity));
}

.progress-bar > :nth-child(5) {
  --text-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--text-opacity));
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity));
}

.progress-bar > :nth-child(5) .activity-progress--inner {
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity));
}

.progress-bar > :nth-child(6) {
  --text-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--text-opacity));
  --border-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity));
}

.progress-bar > :nth-child(6) .activity-progress--inner {
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity));
}

.progress-bar > :nth-child(7) {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.progress-bar > :nth-child(7) .activity-progress--inner {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity));
}

.progress-bar .activity-progress {
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.progress-bar .activity-progress--inner {
  height: 100%;
}

/*# sourceMappingURL=index.0b8a6c3e.css.map */
